<template>
  <div>
    <div class="search_items">
      <v-select
        :items='tourLocations'
        placeholder="Местность"
        height="44"
        item-value="id"
        item-text="name"
        hide-details
        outlined
        v-model="filter.location"
        dense
        clearable
      >
      </v-select>
      <v-text-field
        placeholder="Поиск по названию или описанию"
        height="44"
        hide-details
        outlined
        dense
        v-model="filter.search"
        class="mx-4"
      >
      </v-text-field>
      <v-btn
        height="44"
        width="144"
        color="primary"
        @click="find"
      >
        Найти
      </v-btn>
    </div>
    <div class="add_btn">
      <v-tabs v-model="tab">
          <v-tab @click="goTab('')">Все</v-tab>
          <v-tab @click="goTab(true)">Активные</v-tab>
          <v-tab @click="goTab(false)">Не активные</v-tab>
      </v-tabs>
      <vue-add-button @onClick='onAddTour'>
        Добавить
      </vue-add-button>
    </div>
    <v-card class="pa-4 single_tour_card mb-4" v-for="(item, index) in items.results" :key="index" elevation="2">
      <div class="box_wrapper">
        <div class="box_wrapper__left">
          <div class="main_image_card" :style="{ 'background-image': 'url( ' + item.main_image + '  )' }" />
          <div>
            <h2>{{ item.name }}</h2>
            <p v-html="item.description" class="text_elipsis"></p>
          </div>
        </div>
        <div class="box_wrapper__actions" style="align-items: center;padding: 0;">
          <v-checkbox v-model="item.is_popular" label="Популярный" @change="populateItem(item)"></v-checkbox>

          <v-btn class="first_btn" width="160" height="44" color="#FF8413" outlined @click="editItem(item)"
            ><img class="mr-2" src="@/assets/images/ui/edit.svg" alt="edit" />Изменить</v-btn
          >
          <v-btn width="160" height="44" :color="btnColor(item)" outlined @click="activateItem(item)">
            {{ item.is_active ? "Деактивировать" : "Активировать" }}
          </v-btn>
        </div>
      </div>
    </v-card>
    <div v-if="items.count > 5" class="text-center">
      <v-pagination @input="paginate" v-model="filter.page" :length="pages" circle></v-pagination>
    </div>
    <vue-dialog :model='dialog' :options='dialogOptions' @closeDialog='closeDialog' @onSubmit='onSubmit'>
      <v-form
        ref="form"
        lazy-validation
      >
        <input-content
          :items="inputItems"
          :model="model"
          @onMapClick='onMapClick'
        />
        <v-row>
          <v-col cols="6" v-if="model.id && model.edit_image">
            Главное фото
            <v-img :src="model.edit_image"></v-img>
          </v-col>
          <v-col cols="6" v-if="model.id && model.gallery">
            Дополнительные фото
            <div v-for="(img, index) in model.gallery" :key="index">
              <div class="uploaded_image">
                <v-img  class="gallery_image" :src="img.image"></v-img>
                <v-img
                  @click="deleteGalleryImage(img, index)"
                  width="12"
                  height="16"
                  style="cursor: pointer"
                  class="delete_image"
                  src="@/assets/images/ui/delete.svg"
                ></v-img>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-form>
    </vue-dialog>
  </div>
</template>

<script>
import VueAddButton from '@/components/ui/vueAddButton.vue'
import VueDialog from '@/components/ui/vueDialog.vue'
import InputContent from '@/components/inputs/inputContent.vue'
import {mapActions, mapGetters} from 'vuex'
export default {
  components: {
    VueAddButton,
    VueDialog,
    InputContent
  },
  data: () => ({
    showEditor: false,
    tab: 0,
    filter: {
      page: 1,
      size: 5
    },
    model: {
      latitude: '48.183333',
      longitude: '66.366667',
    },
    locations: [],
    inputItems: [
      {
        type: 'text',
        label: 'Название',
        model: 'name',
        rules: 'required'
      },
      {
        type: 'select',
        label: 'Местность',
        items: [],
        model: 'location',
        getItems: 'getTourLocations',
        rules: 'required'
      },
      {
        type: 'editor',
        label: 'Описание',
        model: 'description'
      },
      {
        type: 'text',
        label: 'Адрес',
        model: 'address',
        rules: 'required'
      },
      {
        type: 'text',
        label: 'Широта',
        cols: 6,
        model: 'latitude',
        rules: 'required'
      },
      {
        type: 'text',
        label: 'Долгота',
        cols: 6,
        model: 'longitude',
        rules: 'required'
      },
      {
        type: 'map',
        latitude: 'latitude', 
        longitude: 'longitude', 
      },
      {
        type: 'image',
        model: 'main_image',
        label: 'Выберите главное фото',
        cols: 6,
        rules: 'required'
      },
      {
        type: 'image',
        model: 'sub_images',
        label: 'Выберите дополнительные фото',
        multiple: true,
        cols: 6,
        rules: 'required'
      },
    ],
    dialog: false,
    dialogOptions: {
      width: '711',
      title: 'Создать тур',
      button: 'Сохранить'
    },
  }),
  computed: {
    ...mapGetters({
      tourLocations: 'tours/getTourLocations',
      items: 'tours/getToursList',
    }),
    pages() {
      return Math.ceil(this.items.count / this.filter.size);
    },
  },
  watch: {
    dialog(){
      if(this.model.edit_image) this.inputItems[7].rules = 'none'
      else this.inputItems[7].rules = 'required'
      if(this.model.gallery && this.model.gallery.length > 0) this.inputItems[8].rules = 'none'
      else this.inputItems[8].rules = 'required'
    }
  },
  async mounted() {
    await this.getTourLocations()
    await this.getTours(this.filter)
  },
  methods: {
    ...mapActions({
      deleteImage: 'tours/deleteImage',
      getGallery: 'tours/getGallery',
      getTours: 'tours/getTours',
      createTour: 'tours/createTour',
      patchTour: 'tours/patchTour',
      uploadMultiple: 'tours/uploadMultiple',
      getTourLocations: 'tours/getTourLocations'
    }),
    async goTab(type) {
      this.filter.page = 1
      this.filter.is_active = type
      await this.getTours(this.filter)
    },
    async find() {
      this.filter.page = 1
      await this.getTours(this.filter)
    },
    async paginate() {
      await this.getTours(this.filter)
    },
    async populateItem(item) {
      try {
        this.$loading(true)
        const FD = new FormData()
        FD.append('is_popular', item.is_popular)
        FD.append('id', item.id)
        await this.patchTour(FD)
      } catch(e) {
        console.error(e);
      } finally {
        this.$loading(false)
      }
    },
    async editItem(item) {
      this.model = this.$copy(item)
      this.model.location = this.model.location.id
      this.dialogOptions = {
        width: '711',
        title: 'Изменить тур',
        button: 'Редактировать'
      }
      this.model.edit_image = this.$copy(item.main_image)
      this.model.gallery = await this.getGallery(item.id)
      this.model.gallery_edit = this.model.gallery.map(el => el.id)
      this.model.main_image = null
      this.model.sub_images = []
      this.model.delete_gallery = []
      setTimeout(() => {this.showEditor = true}, 200);
      this.dialog = true
    },
    async activateItem(item) {
      try {
        this.$loading(true)
        item.is_active = !item.is_active
        const FD = new FormData()
        FD.append('is_active', item.is_active)
        FD.append('id', item.id)
        await this.patchTour(FD)
      } catch(e) {
        console.error(e);
      } finally {
        this.$loading(false)
      }
    },
    deleteGalleryImage(img, index) {
      this.dialog = false;
      this.$nextTick(function () {
        this.model.gallery.splice(index, 1)
        this.model.delete_gallery.push(img.id)
        this.dialog = true;
      })
    },
    btnColor(item) {
      return !item.is_active ? "#06B04A" : "#FF475D";
    },
    onMapClick(e) {
      this.model.latitude = e.get("coords")[0];
      this.model.longitude = e.get("coords")[1];
    },
    async deleteEditImages() {
      try {
        const promises = []
        this.model.delete_gallery.forEach(id => {
          promises.push(this.deleteImage(id))
        })
        await Promise.all(promises)
      } catch(e) {
        console.error(e);
      }
    },
    async onSubmit() {
      if (!this.$refs.form.validate()){
        if(!this.model.edit_image) return 
      }
      try {
        this.$loading(true)
        const FD = new FormData()
        Object.keys(this.model).forEach(model => {
          FD.append(model, this.model[model])
        })
        if (!this.model.main_image || !this.model.main_image.name) {
          FD.delete('main_image')
        }
        let id
        if (this.model.id) {
          id = this.model.id
          await this.patchTour(FD)
          this.deleteEditImages()
        } else {
          id = (await this.createTour(FD)).id
        }
        let mul_fd = new FormData()
        const promises = []
        if (this.model.sub_images) {
          this.model.sub_images.forEach(image => {
            mul_fd.append('image', image)
            mul_fd.append('tour', id)
            promises.push(this.uploadMultiple(mul_fd))
            mul_fd = new FormData()
          })
        }
        await Promise.all(promises)
        this.$loading(false)
      } catch(e) {
        console.error(e);
      } finally {
        this.$loading(false)
        this.dialog = false
        await this.getTours()
      }
    },
    closeDialog() {
      this.dialog = false
      if (this.$refs.form) {
        this.$refs.form.reset()
      }
      this.model = {
        latitude: '48.183333',
        longitude: '66.366667',
        main_image: {},
        sub_images: [],
      }
    },
    onAddTour() {
      this.dialogOptions = {
        width: '711',
        title: 'Создать тур',
        button: 'Сохранить'
      }
      this.model = {
        description: 'Описание',
        latitude: '48.183333',
        longitude: '66.366667',
        main_image: null,
        sub_images: [],
      }
      setTimeout(() => {this.showEditor = true}, 200);
      this.dialog = true
    }
  }
}
</script>

<style lang="scss" scoped>
.search_items {
  display: grid;
  grid-template-columns: 250px 1fr 145px;
}
.add_btn {
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.uploaded_image {
  position: relative;
  margin-bottom: 12px;
  &:hover {
    .gallery_image {
      transition: .2s;
      filter: grayscale(100%);
    }
    .delete_image {
      opacity: 1;
    }
  }
  .delete_image {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    opacity: 0;
  }
}
.text_elipsis {
  font-size: 16px;
  line-height: 19px;
  color: #021011;
  margin-top: 12px;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.box_wrapper {
  display: grid;
  grid-template-columns: 1fr 160px;
  width: 100%;
  justify-content: space-between;
  &__left {
    display: grid;
    grid-template-columns: 191px 1fr;
    grid-column-gap: 14px;
    h2 {
      font-size: 16px;
      line-height: 19px;
      color: #021011;
      margin-top: 5px;
    }
  }
  &__actions {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    padding: 20px 0;
    .first_btn {
      margin-bottom: 20px;
    }
  }
  .main_image_card {
    width: 191px;
    height: 151px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 5px;
  }
}
</style>